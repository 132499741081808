import { create } from "apisauce";
import { BehaviorSubject } from "rxjs";
import { STORAGE_KEYS } from "../enums/sharedEnums";
import { removeUser } from "../../../features/Auth Slice/authSlice";
import { store } from "../../../store/store";
import spinnerSvc from "../utils/spinner-service";
import { storeTokens, storeUser } from "../utils/storingInStorage";
import { notificationSocket } from "web-app-library";
import { IProfileImageUpdateReqBody } from "../types/IUser.interface";
import {
  apiBaseUrlAuth,
  apiBaseUrlUsers,
} from "../constants/environment.constants";
const isAuthenticatedSub = new BehaviorSubject(false);
// const signedIn$ = new Subject();

export const authApi = create({
  baseURL: apiBaseUrlAuth,
  headers: { Accept: "application/json" },
});

authApi.axiosInstance.interceptors.request.use(
  async (config) => {
    spinnerSvc.start();
    return config;
  },
  (err) => console.error(err)
);

authApi.axiosInstance.interceptors.response.use(
  (response) => {
    spinnerSvc.stop();
    return response;
  },

  async (err) => {
    spinnerSvc.stop();
    return Promise.reject(err);
  }
);

export const usersApi = create({
  baseURL: apiBaseUrlUsers,
  headers: { Accept: "application/json" },
});

usersApi.axiosInstance.interceptors.request.use(
  async (config: any) => {
    const accessToken = localStorage.getItem(STORAGE_KEYS?.ACC_TOKEN);
    if (accessToken !== "") {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }
    spinnerSvc.start();
    return config;
  },
  (err) => console.error(err)
);

usersApi.axiosInstance.interceptors.response.use(
  (response) => {
    spinnerSvc.stop();
    return response;
  },

  (err) => {
    spinnerSvc.stop();
    return Promise.reject(err);
  }
);

export const getTokens = async (
  rememberMe: string,
  clientId: string,
  code: string
) => {
  try {
    const res: any = await authApi.get(
      `/tokens?${clientId && "client_id=" + clientId}${
        clientId && code ? "&" : ""
      }${code && "code=" + code}`,
      undefined,
      { headers: { Accept: "application/json" } }
    );
    if (res?.ok) {
      storeTokens(res?.data, rememberMe === "true" ? true : false);
      storeUser(res?.data?.access_token);
      return { data: res?.data, ok: true };
    } else return { error: true, ok: false };
  } catch (err) {
    console.log("error: ", err);
  }
};

export const fetchAccountInfo = async () => {
  try {
    const res: any = await usersApi.get(`/users/user`);
    if (res?.ok) {
      localStorage.setItem(STORAGE_KEYS.ROLE, res?.data?.roles[0]);
      return { data: res?.data, ok: true };
    } else return { error: true, ok: false };
  } catch (err) {
    console.log("error: ", err);
  }
};
export const fetchNotifications = async () => {
  try {
    const res: any = await usersApi.get(`/notifications`);
    if (res?.ok) {
      return { data: res?.data, ok: true };
    } else return { error: true, ok: false };
  } catch (err) {
    console.log("error: ", err);
  }
};
export const fetchNotificationsUnread = async () => {
  try {
    const res: any = await usersApi.get(`/notifications/unread`);
    if (res?.ok) {
      return { data: res?.data, ok: true };
    } else return { error: true, ok: false };
  } catch (err) {
    console.log("error: ", err);
  }
};

export const refreshToken = async () => {
  try {
    const rfToken = localStorage.getItem(STORAGE_KEYS?.RF_TOKEN);
    const res: any = await authApi.post(`/refresh`, undefined, {
      headers: { Authorization: `Bearer ${rfToken}` },
    });
    if (res?.ok) {
      storeTokens(res?.data);
      return res?.data?.access_token;
    }
    throw new Error("expired token");
  } catch (err) {
    console.log("error: ", err);
  }
};

export const postFiles = async (formData: FormData) => {
  const accToken = localStorage.getItem(STORAGE_KEYS.ACC_TOKEN);
  try {
    const response = await usersApi.post(`/users/upload`, formData, {
      headers: { Authorization: `Bearer ${accToken}` },
    });
    if (response && response?.ok) {
      return { data: response?.data, ok: true };
    } else {
      throw new Error("Couldn't upload file");
    }
  } catch (err: any) {
    console.log("error: ", err);
  }
};

export const subNewsLetter = async (email: string) => {
  try {
    const res: any = await usersApi.post(`/newsletter/subscribe`, {
      email: email,
    });
    if (res && res?.ok) {
      return { data: res.data, ok: true };
    } else return { error: res?.data, ok: false };
  } catch (err) {
    console.log("error: ", err);
  }
};

export const completeProfile = async (
  redirectUri: string,
  clientID: string,
  reqBody: IProfileImageUpdateReqBody
) => {
  const accToken = localStorage.getItem(STORAGE_KEYS.ACC_TOKEN);
  try {
    const res: any = await authApi.post(
      `/updateProfile?client_id=${clientID}&redirect_uri=${redirectUri}`,
      reqBody,
      {
        headers: { Authorization: `Bearer ${accToken}` },
      }
    );
    if (res?.ok) {
      storeUser(res?.data?.access_token);
      return { data: res?.data, ok: true };
    } else return { error: true, ok: false };
  } catch (err: any) {
    console.log("error: ", err);
  }
};

export const signOut = () => {
  spinnerSvc.start();

  setTimeout(() => {
    spinnerSvc.stop();
  }, 500);
  notificationSocket?.disconnectSocket();
  store.dispatch(removeUser());
};

export const isAuthenticated = () => {
  return isAuthenticatedSub.asObservable();
};

export const setIsAuthenticated = (isAuthenticated: boolean) => {
  isAuthenticatedSub.next(isAuthenticated);
};
