import React, { useState } from "react";
import Footer from "../footer/Footer";
import PrimaryHeader from "../Header/PrimaryHeader";
import SecondaryHeader from "../Header/SecondaryHeader";

const MainWrapper = ({ children }: { children: React.ReactNode }) => {
  const [isOpen, setIsOpen] = useState(false);
  const handleClose = () => {
    setIsOpen(false)
  }
  return (
    <div onClick={() => { isOpen && handleClose() }}>
      <PrimaryHeader />
      <SecondaryHeader isOpen={isOpen} setIsOpen={setIsOpen} />
      {children}
      <Footer />
    </div>
  );
};

export default MainWrapper;
