import { ccfApi } from "../utils/axios";

const getBlogs = async (category?: string, title?: string, limit?: number, page?: number) => {
  const resp = await ccfApi.get<any>(`/blogs`, {
    category: category,
    limit: limit,
    title: title,
    page: page,
  });

  if (resp.ok && resp.data) {
    return resp.data;
  } else {
    return null;
  }
};

export const blogSvc = { getBlogs };
