import React from "react";
import PriceDetail from "./PriceDetail";
import SelectMethod from "./SelectMethod";

const PaymentMethod = () => {
  return (
    <>
      <div className="bg-white rounded-[16px] mb-4 md:mb-8 xl:mb-12">
        <PriceDetail />
      </div>
      <div>
        <SelectMethod />
      </div>
    </>
  );
};

export default PaymentMethod;
