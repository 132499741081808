import { IContactUs } from "../types/IContactUs.interface";
import { usersApi } from "./auth.service";

const getAllContacts = async () => {
  const resp = await usersApi.get<any>(``);
  if (resp.ok && resp.data) {
    return resp.data;
  } else {
    return null;
  }
};

const createContact = async (contactInfo: IContactUs) => {
  const resp = await usersApi.post<any>(`/contact/create`, contactInfo);
  if (resp.ok && resp.data) {
    return resp.data;
  } else {
    return null;
  }
};

export const contactSvc = { getAllContacts, createContact };
