import React, { useEffect, useState } from "react";
import { FiPhoneCall } from "react-icons/fi";
import { IoMailOutline } from "react-icons/io5";
import { ICoordinates } from "../../../proffer_module/types/IFindCenterParams.interface";
import CenterViewBanner from "../../components/centerViewBanner/CenterViewBanner";
import MainWrapper from "../../components/layout/MainWrapper";
import { ERR_MSGS } from "../../constants/errorMsgs";
import {
  initContactUsForm,
  initContactUsFormErrors,
} from "../../constants/initialState";
import { regexPatterns } from "../../constants/regexPatterns";
import { contactSvc } from "../../services/contact.service";
import { IContactUs } from "../../types/IContactUs.interface";
import ContactUsMaps from "../../components/maps/ContactUsMaps";
import HeroBanner from "../../../medEvaluation_module/components/medEvaluationBanner/HeroBanner";

const states = [{ label: "NewYork", value: "ny" }];

const ContactUs = () => {
  const [contactUsForm, setContactUsForm] =
    useState<IContactUs>(initContactUsForm);
  const [contactUsFormErrors, setContactUsFormErrors] = useState(
    initContactUsFormErrors
  );
  const [ccfLocation, setCcfLocation] = useState<ICoordinates>();
  const [isDisabled, setIsDisabled] = useState(true);

  const handleInputChange = (
    event:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLTextAreaElement>
      | any
  ) => {
    const { name, value } = event?.target ? event.target : event;
    setContactUsForm((prev) => ({ ...prev, [name]: value }));
  };

  const handleFocus = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name } = event?.target;
    const fieldMappings: { [key: string]: string } = {
      email: "emailError",
      password: "passwordError",
      phoneNumber: "phoneNumberError",
    };
    const errorKey = fieldMappings[name];
    if (errorKey) {
      setContactUsFormErrors((prev) => ({
        ...prev,
        [errorKey]: "",
      }));
    }
  };

  const handleBlur = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name } = event?.target;
    const fieldMappings: { [key: string]: { key: string; errorMsg: string } } =
      {
        email: {
          key: "emailError",
          errorMsg: ERR_MSGS?.Invalid_Email,
        },
        password: {
          key: "passwordError",
          errorMsg: ERR_MSGS.Invalid_Password_Pattern,
        },
        phoneNumber: {
          key: "phoneNumberError",
          errorMsg: ERR_MSGS.Phone_Number_Length,
        },
      };

    const fieldMapping = fieldMappings[name];
    if (fieldMapping) {
      const { key, errorMsg } = fieldMapping;
      const { value } = event?.target;
      const regexPatternFind: { [key: string]: RegExp } = {
        email: regexPatterns?.email, // Email pattern
        password: regexPatterns?.password, // Password pattern
        phoneNumber: regexPatterns?.phoneNumber,
      };

      if (name in regexPatternFind && !regexPatternFind[name].test(value)) {
        setContactUsFormErrors((prev) => ({ ...prev, [key]: errorMsg }));
      }
    }
  };

  useEffect(() => {
    // const { city, ...filteredProfileForm } = contactUsForm;
    console.log("check data: ", contactUsForm);
    const isFieldEmpty = Object.values(contactUsForm).some(
      (value) => value === ""
    );

    const noError = Object.values(contactUsFormErrors).every(
      (value) => value === ""
    );

    if (!isFieldEmpty && noError) setIsDisabled(false);
    else setIsDisabled(true);
  }, [contactUsForm, contactUsFormErrors]);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e?.preventDefault();

    console.log(contactUsForm);
    if (contactUsForm) {
      const res = await contactSvc.createContact(contactUsForm);
      console.log("res from contact", res);
    }
  };

  const handleEmailClick = () => {
    const mailtoLink = `mailto:frontdesk@childcareforward.com`;
    window.location.href = mailtoLink;
  };
  const handleLocationClick = () => {
    setCcfLocation({ lat: 39.1219948, lng: -76.6451697 });
    // const locationLink = `https://www.google.com/maps/search/?api=1&query=44+Danwers,+NY+City,+USA,+70-102`;
    // window.open(locationLink, '_blank');
  };

  const handlePhoneClick = () => {
    const phoneLink = `tel:0016676779200`;
    window.location.href = phoneLink;
  };

  useEffect(() => {
    window.scroll(0, 0);
  }, []);
  return (
    <>
      <MainWrapper>
        <HeroBanner
          title="Taking the Child Care Industry"
          highlightedText="Forward!"
          paragraphText={""}
          bgClass="https://a360csastorage.blob.core.windows.net/childcare/18403007-c9b1-46ee-8301-f3dd221c45c5-fileName-contactUs-banner.png"
        />{" "}
        <div className="mt-16 container">
          <h2 className="text-2xl md:text-xll text-secondary font-semibold mb-10">
            Have a question?
          </h2>
          {/* <p>
              Lorem ipsum dolor sit amet consectetur adipiscing eli mattis sit
              phasellus mollis sit aliquam sit <br /> nullam.
            </p> */}
          <h3 className="text-xl text-secondary font-semibold mb-6">
            Fill out the form for inquiry
          </h3>
          <div className=" flex lg:flex-row flex-col mb-4 sm:mb-8 md:mb-16 xl:mb-24 items-start lg:justify-between gap-12 w-full">
            <div className="md:basis-2/3 w-full">
              <form onSubmit={handleSubmit}>
                <div className="flex md:flex-row flex-col gap-4 mb-6 ">
                  <div className="md:basis-1/2 w-full">
                    <label className="text-sm text-secondaryVariant">
                      Full Name
                    </label>
                    <input
                      type="text"
                      className="w-full mt-2 border border-secondaryVariant2 rounded-lg py-3.5 ps-2 pe-3"
                      placeholder="Enter your name..."
                      name="fullName"
                      onChange={handleInputChange}
                      onFocus={handleFocus}
                      onBlur={handleBlur}
                    />
                    {contactUsFormErrors?.fullNameError && (
                      <p className="text-red-600 pl-5">
                        {contactUsFormErrors?.fullNameError}
                      </p>
                    )}
                  </div>
                  <div className="md:basis-1/2 basis-full">
                    <label className="text-sm text-secondaryVariant">
                      Phone Number
                    </label>
                    <input
                      type="text"
                      className="w-full mt-2 border border-secondaryVariant2 rounded-lg py-3.5 ps-2 pe-3"
                      placeholder="Enter your Phone number..."
                      name="phoneNumber"
                      onChange={handleInputChange}
                      onFocus={handleFocus}
                      onBlur={handleBlur}
                    />
                    {contactUsFormErrors?.phoneNumberError && (
                      <p className="text-red-600 pl-5">
                        {contactUsFormErrors?.phoneNumberError}
                      </p>
                    )}
                  </div>
                </div>
                <div className="flex flex-row gap-4 mb-6">
                  <div className="w-full">
                    <label className="text-sm text-secondaryVariant">
                      Email{" "}
                    </label>
                    <input
                      type="text"
                      className="w-full mt-2 border border-secondaryVariant2 rounded-lg py-3.5 ps-2 pe-3"
                      placeholder="Enter your email..."
                      name="email"
                      onChange={handleInputChange}
                      onFocus={handleFocus}
                      onBlur={handleBlur}
                    />
                    {contactUsFormErrors?.emailError && (
                      <p className="text-red-600 pl-5">
                        {contactUsFormErrors?.emailError}
                      </p>
                    )}
                  </div>
                  {/* <div className="basis-1/2">
                    <label className="text-sm text-secondaryVariant">
                      State{" "}
                    </label>
                    <Select
                      className=" text-start mt-2"
                      placeholder="Select your state"
                      options={states}
                      styles={{
                        control: (baseStyles, state) => ({
                          ...baseStyles,
                          borderColor: state.isFocused ? "grey" : "#CED2DA",
                          padding: "8px 0 8px 40px",
                          borderRadius: "8px",
                        }),
                      }}
                      onChange={(option: any) =>
                        handleInputChange({
                          target: { name: "state", value: option.value },
                        })
                      }
                    />
                  </div> */}
                </div>
                <div className="mb-8 lg:mb-16">
                  <label className="text-sm text-secondaryVariant">
                    Message{" "}
                  </label>
                  <textarea
                    className="w-full mt-2 border border-secondaryVariant2 rounded-lg py-3.5 ps-2 pe-3"
                    name="message"
                    id="message"
                    placeholder="Enter your message"
                    onChange={handleInputChange}
                  ></textarea>{" "}
                </div>
                <button
                  className={`btnPrimary max-w-[182px] ${
                    isDisabled ? "opacity-50" : "cursor-pointer"
                  }`}
                  type="submit"
                  disabled={isDisabled}
                >
                  Submit
                </button>
              </form>
            </div>
            <div className="lg:basis-1/3 lg:max-w-[416px] w-full">
              <h3 className="text-xl text-secondary font-semibold mb-4">
                Need more help?
              </h3>
              <div className="flex items-start bg-secondaryNeutral rounded-xl p-6 gap-6 mb-4">
                <div className="p-2.5 border border-secondaryVariant2 rounded-md text-secondaryVariant2">
                  <FiPhoneCall className="w-6 h-6" />
                </div>
                <div>
                  <p className="text-lg text-secondary font-semibold mb-2">
                    Give Us A Call
                  </p>
                  <p className="text-lg text-secondaryVariant mb-2">
                    Speak to our friendly team.
                  </p>
                  <button
                    className="text-sm text-secondaryVariant underline font-semibold"
                    onClick={handlePhoneClick}
                  >
                    667-677-9200
                  </button>
                </div>
              </div>
              <div className="flex items-start bg-secondaryNeutral rounded-xl p-6 gap-6 mb-4">
                <div className="p-2.5 border border-secondaryVariant2 rounded-md text-secondaryVariant2">
                  <IoMailOutline className="w-6 h-6" />
                </div>
                <div>
                  <p className="text-lg text-secondary font-semibold mb-2">
                    Email Questions
                  </p>
                  <p className="text-lg text-secondaryVariant mb-2">
                    We’re here to help{" "}
                  </p>
                  <button
                    className="text-sm text-secondaryVariant underline font-semibold break-all"
                    onClick={handleEmailClick}
                  >
                    frontdesk@childcareforward.com{" "}
                  </button>
                </div>
              </div>
              {/* <div className="flex items-start bg-secondaryNeutral rounded-xl p-6 gap-6 mb-4">
                <div className="p-2.5 border border-secondaryVariant2 rounded-md text-secondaryVariant2">
                  <HiOutlineLocationMarker className="w-6 h-6" />
                </div>
                <div>
                  <p className="text-lg text-secondary font-semibold mb-2">
                    Address{" "}
                  </p>
                  <p className="text-base text-secondaryVariant mb-2">
                    Visit our headquarter{" "}
                  </p>
                  <button
                    className="text-sm text-secondaryVariant underline font-semibold"
                    onClick={handleLocationClick}
                  >
                    View on google map{" "}
                  </button>
                </div>
              </div> */}
            </div>
          </div>
          <div className="">
            {/* <img
              className="w-full"
              src="https://a360csastorage.blob.core.windows.net/childcare/27be2ac1-84d0-4491-b6f6-35011163963d-fileName-contactUs-map-img.png"
              alt="map"
            /> */}
            {/* <div style={{ height: "56vh", width: "100%" }}>
              <ContactUsMaps ccfLocation={ccfLocation as ICoordinates} />
            </div> */}
          </div>
        </div>
      </MainWrapper>
    </>
  );
};

export default ContactUs;
