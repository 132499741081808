import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { ccfRoutes } from "../../ccfWebsite_module/routes/routes";
import { STORAGE_KEYS } from "../enums/sharedEnums";

interface Props {
    role?: string;
}
const ProtectedRoute: React.FC<Props> = ({ role }) => {
    const isAuthenticated = !!localStorage.getItem(STORAGE_KEYS.USER);
    const storedRole = localStorage.getItem(STORAGE_KEYS.ROLE);

    if (!isAuthenticated) {
        return <Navigate to={ccfRoutes.home} />;
    }

    if (!storedRole) {
        return <Navigate to={ccfRoutes.home} />;
        // switch (storedRole) {
        //     case USER_ROLES.CENTER:
        //         return <Navigate to={sharedRoutes.dashboard} />;
        //     case USER_ROLES.PARENT:
        //         return <Navigate to={profferRoutes.proffer_list} />;
        //     default:
        //         // return <Navigate to={profferRoutes.home} />;
        //         break;
        // }
    }
    if (role && role !== storedRole) {
        return <Navigate to={ccfRoutes.home} />;

    }

    return <Outlet />;
};

export default ProtectedRoute;
