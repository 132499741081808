import React, { useEffect } from "react";
import MainWrapper from "../../components/layout/MainWrapper";
import { Link } from "react-router-dom";

const PrivacyStatement = () => {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);
  return (
    <>
      <MainWrapper>
        <div className="bg-secondaryNeutral py-12">
          <div className="container">
            <h2 className="text-4xl text-secondary font-semibold mb-4">
              Privacy Policy
            </h2>
            <span className="text-lg text-secondaryVariant font-medium">
              Effective Date: November 1, 2023
            </span>
          </div>
        </div>
        <div className="container pt-6 md:pt-[54px]">
          {/* <p className="text-base text-secondaryVariant mb-4 sm:mb-8 md:mb-16 xl:mb-24">
            Your privacy is important to us. This privacy statement explains the
            personal data Microsoft processes, how Microsoft processes it, and
            for what purposes. <br /> <br />
            Microsoft offers a wide range of products, including server products
            used to help operate enterprises worldwide, devices you use in your
            home, software that students use at school, and services developers
            use to create and host what’s next. References to Microsoft products
            in this statement include Microsoft services, websites, apps,
            software, servers, and devices.
            <br />
            Please read the product-specific details in this privacy statement,
            which provide additional relevant information. This statement
            applies to the interactions Microsoft has with you and the Microsoft
            products listed below, as well as other Microsoft products that
            display this statement.
            <br /> <br /> Young people may prefer starting with{" "}
            <span className="underline"> the Privacy for young people </span>
            page. That page highlights information that may be helpful for young
            people.
            <br /> <br /> For individuals in the United States, please refer to
            our
            <span className="underline"> U.S. State Data Privacy Notice </span>
            and the Washington State
            <span> Consumer Health Data Privacy Policy </span>for additional
            information about the processing of your personal data, and your
            rights under applicable U.S. State data privacy laws.
          </p> */}
          <div className="mb-4 md:mb-6">
            <h2 className="text-2xl text-secondary font-semibold">
              1. Introduction
            </h2>
            <hr className="my-6" />
            <p className="text-base text-secondaryVariant font-normal">
              Welcome to Child Care Forward!   We value your privacy and are
              committed to protecting your personal information. This Privacy
              Policy outlines how we collect, use, and safeguard your
              information when you visit our Site or use our services.
            </p>
          </div>
          <div className="mb-4 md:mb-6">
            <h2 className="text-2xl text-secondary font-semibold">
              2. Information We Collect
            </h2>
            <hr className="my-6" />
            <p className="text-base text-secondaryVariant font-normal">
              We may collect the following types of information:
              <ul className="list-disc list-inside ml-4 mt-6">
                <li>
                  Personal Information: Name, email address, phone number, and
                  other identifying information you provide when registering or
                  using our services. 
                </li>
                <li>
                  Usage Data: Information about how you interact with our Site,
                  including IP address, browser type, pages visited, and time
                  spent on those pages. 
                </li>
                <li>
                  Cookies: Small data files stored on your device to enhance
                  your browsing experience. You can manage cookie preferences in
                  your browser settings. 
                </li>
              </ul>
            </p>
          </div>
          <div className="mb-4 md:mb-6">
            <h2 className="text-2xl text-secondary font-semibold">
              3. How We Use Your Information
            </h2>
            <hr className="my-6" />
            <p className="text-base text-secondaryVariant font-normal">
              We use your information for various purposes, including:
              <ul className="list-disc list-inside ml-4 mt-6">
                <li>To provide and maintain our services. </li>
                <li>To notify you about changes to our services. </li>
                <li>
                  To allow you to participate in interactive features when you
                  choose to do so. 
                </li>
                <li>To provide customer support. </li>
                <li>
                  To gather analysis or valuable information so that we can
                  improve our services. 
                </li>
                <li>To monitor the usage of our Site. </li>
                <li>To detect, prevent, and address technical issues. </li>
              </ul>
            </p>
          </div>
          <div className="mb-4 md:mb-6">
            <h2 className="text-2xl text-secondary font-semibold">
              4. Sharing Your Information
            </h2>
            <hr className="my-6" />
            <p className="text-base text-secondaryVariant font-normal">
              We do not sell, trade, or otherwise transfer your personal
              information to outside parties except to provide our services or
              as required by law. We may share information with:
              <ul className="list-disc list-inside ml-4 mt-6">
                <li>
                  Service providers who assist us in operating our Site and
                  services. 
                </li>
                <li>Compliance with legal obligations. </li>
              </ul>
            </p>
          </div>
          <div className="mb-4 md:mb-6">
            <h2 className="text-2xl text-secondary font-semibold">
              5. Data Security
            </h2>
            <hr className="my-6" />
            <p className="text-base text-secondaryVariant font-normal">
              We implement appropriate security measures to protect your
              personal information. However, no method of transmission over the
              internet or electronic storage is 100% secure.
            </p>
          </div>
          <div className="mb-4 md:mb-6">
            <h2 className="text-2xl text-secondary font-semibold">
              6. Your Rights
            </h2>
            <hr className="my-6" />
            <p className="text-base text-secondaryVariant font-normal">
              You have the right to:
              <ul className="list-disc list-inside ml-4 mt-6">
                <li>Access the personal information we hold about you. </li>
                <li>Request correction of any inaccurate data. </li>
                <li>Request deletion of your personal information. </li>
                <li>Withdraw consent to processing your data. </li>
              </ul>
            </p>
          </div>
          <div className="mb-4 md:mb-6">
            <h2 className="text-2xl text-secondary font-semibold">
              7. Changes to This Privacy Policy
            </h2>
            <hr className="my-6" />
            <p className="text-base text-secondaryVariant font-normal">
              We may update this Privacy Policy from time to time. We will
              notify you of any changes by posting the new policy on this page.
            </p>
          </div>
          <div className="mb-4 md:mb-16">
            <h2 className="text-2xl text-secondary font-semibold">
              8. Contact Us
            </h2>
            <hr className="my-6" />
            <p className="text-base text-secondaryVariant font-normal">
              If you have any questions about this Privacy Policy, please
              contact us at:
              <br />
              <Link
                to={`mailto:frontdesk@childcareforward.com`}
                className="font-medium"
              >
                frontdesk@childcareforward.com 
              </Link>
            </p>
          </div>
          {/* <div className="flex flex-row gap-16 items-start">
            <div className="basis-1/3 border rounded-lg p-2.5 bg-secondaryVariant2">
              <ul className="space-y-5 py-2.5">
                <li className="text-base text-secondary underline">
                  Personal data we collect
                </li>
                <li className="text-base text-secondary underline">
                  How we use personal data
                </li>
                <li className="text-base text-secondary underline">
                  Reasons we share personal data
                </li>
                <li className="text-base text-secondary underline">
                  How to access and control your personal data
                </li>
                <li className="text-base text-secondary underline">
                  Cookies and similar technologies
                </li>
                <li className="text-base text-secondary underline">
                  Microsoft account
                </li>
                <li className="text-base text-secondary underline">
                  Collection of data from children
                </li>
              </ul>
            </div>
            <div className="basis-2/3">
              <div className="mb-4 md:mb-10 xl:mb-16">
                <h2 className="text-2xl text-secondary">
                  Personal data we collect
                </h2>
                <hr className="my-6" />
                <p className="text-base text-secondaryVariant">
                  Lorem ipsum dolor sit amet, consectetur cdolor col adipiscing
                  elit. Integer mattis nunc augue vel lacinia erat euismod ut.
                  Sed eleifend tellus nonole tincidunt aliquet. Fusce aliquam mi
                  felis.Lorem ipsum dolor sit amet, consectetur cdolor col
                  adipiscing elit. Integer mattis nunc augue vel lacinia erat
                  euismod ut. Sed eleifend tellus nonole tincidunt aliquet.
                  Fusce aliquam mi felis.Lorem ipsum dolor sit amet, consectetur
                  cdolor col adipiscing elit. Integer mattis nunc augue vel
                  lacinia erat euismod ut. Sed eleifend tellus nonole tincidunt
                  aliquet. Fusce aliquam mi felis.Lorem ipsum dolor sit amet,
                  consectetur cdolor col adipiscing elit. Integer mattis nunc
                  augue vel lacinia erat euismod ut. Sed eleifend tellus nonole
                  tincidunt aliquet. Fusce aliquam mi felis.Lorem ipsum dolor
                  sit amet, consectetur cdolor col adipiscing elit. Integer
                  mattis nunc augue vel lacinia erat euismod ut. Sed eleifend
                  tellus nonole tincidunt aliquet. Fusce aliquam mi felis.Lorem
                  ipsum dolor sit amet, consectetur cdolor col adipiscing elit.
                  Integer mattis nunc augue vel lacinia erat euismod ut. Sed
                  eleifend tellus nonole tincidunt aliquet. Fusce aliquam mi
                  felis.
                </p>
              </div>
              <div className="mb-4 md:mb-10 xl:mb-16">
                <h2 className="text-2xl text-secondary">
                  How we use personal data
                </h2>
                <hr className="my-6" />
                <p className="text-base text-secondaryVariant">
                  Microsoft uses the data we collect to provide you with rich,
                  interactive experiences. In particular, we use data to:
                </p>
                <ul className="ps-7">
                  <li className="text-base text-secondaryVariant list-disc">
                    Provide our products, which includes updating, securing, and
                    troubleshooting, as well as providing support. It also
                    includes sharing data, when it is required to provide the
                    service or carry out the transactions you request.
                  </li>
                  <li className="text-base text-secondaryVariant list-disc">
                    Improve and develop our products.
                  </li>
                  <li className="text-base text-secondaryVariant list-disc">
                    Personalize our products and make recommendations.
                  </li>
                  <li className="text-base text-secondaryVariant list-disc">
                    Advertise and market to you, which includes sending
                    promotional communications, targeting advertising, and
                    presenting you with relevant offers.
                  </li>
                </ul>
                <p className="text-base text-secondaryVariant">
                  We also use the data to operate our business, which includes
                  analyzing our performance, meeting our legal obligations,
                  developing our workforce, and doing research. <br /> In
                  carrying out these purposes, we combine data we collect from
                  different contexts (for example, from your use of two
                  Microsoft products) or obtain from third parties to give you a
                  more seamless, consistent, and personalized experience, to
                  make informed business decisions, and for other legitimate
                  purposes. <br /> Our processing of personal data for these
                  purposes includes both automated and manual (human) methods of
                  processing. Our automated methods often are related to and
                  supported by our manual methods. For example, to build, train,
                  and improve the accuracy of our automated methods of
                  processing (including artificial intelligence or AI), we
                  manually review some of the output produced by the automated
                  methods against the underlying data.
                </p>
              </div>
              <div className="mb-4 md:mb-10 xl:mb-16">
                <h2 className="text-2xl text-secondary">
                  Reasons we share personal data
                </h2>
                <hr className="my-6" />
                <p className="text-base text-secondaryVariant">
                  We share your personal data with your consent or to complete
                  any transaction or provide any product you have requested or
                  authorized. We also share data with Microsoft-controlled
                  affiliates and subsidiaries; with vendors working on our
                  behalf; when required by law or to respond to legal process;
                  to protect our customers; to protect lives; to maintain the
                  security of our products; and to protect the rights and
                  property of Microsoft and its customers. <br /> Please note
                  that, as defined under certain U.S. state data privacy laws,
                  “sharing” also relates to providing personal data to third
                  parties for personalized advertising purposes. Please see the
                  <span className="underline"> U.S. State Data Privacy</span>
                  section below and our{" "}
                  <span className="underline">
                    {" "}
                    U.S. State Data Privacy Laws Notice
                  </span>
                  for more information.
                </p>
              </div>
            </div>
          </div> */}
        </div>
      </MainWrapper>
    </>
  );
};

export default PrivacyStatement;
