import { configureStore } from "@reduxjs/toolkit";
import authReducer from "../features/Auth Slice/authSlice";
import notificationsReducer from "../features/Notification/notificationsSlice";

export const store = configureStore({
  reducer: {
    auth: authReducer,
    notifications: notificationsReducer
  },
});
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
