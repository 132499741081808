import {QueryClient} from '@tanstack/react-query';
 
export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 1000 * 60 * 5, // Data will be considered fresh for 5 minutes
      retry: 2, // Retry failed requests 2 times
      refetchOnWindowFocus: false, // Disable refetch when app comes into focus
      refetchOnReconnect: true, // Refetch on reconnect to network
      refetchOnMount: false, // Disable refetch on component mount
      gcTime:Infinity
    },
    mutations: {
      retry: 2, // Retry mutations 2 times in case of failure
      
    },
  
  },
});