import React from "react";
import { Tooltip as ReactTooltip } from "react-tooltip";

interface Props {
  id: string;
  place?: "top" | "bottom" | "left" | "right";
  className?: string;
  content: string;
  isWhite?:boolean
}
export const TooltipComponent = ({
  id,
  place = "top",
  className = " max-w-[400px]",
  content,
  isWhite
}: Props) => {
  return (
    <ReactTooltip
      id={id}
      place={place}
      content={content}
      className={`${className} ${isWhite ? 'bg-white text-[#000_!important] shadow-xl' : 'bg-primary text-white'}`}
      html={ content}
    />
  );
};
