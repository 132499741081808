import { lazy, Suspense } from "react";
import { Navigate, RouteObject } from "react-router-dom";
import LoaderSpinner from "./components/Loader/Loader";
import { sharedRoutes } from "./routes/routes";
import ProtectedRoute from "./components/RouteProtection";
import { STORAGE_KEYS, USER_ROLES } from "./enums/sharedEnums";
import { ccfRoutes } from "../ccfWebsite_module/routes/routes";
const CenterProfile = lazy(() => import("../proffer_module/pages/Profiles/CenterProfile"));
const ParentProfileHistory = lazy(() => import("../proffer_module/pages/Profiles/ParentProfileHistory"));
const DashboardPaymentMethod = lazy(() => import("../proffer_module/pages/parentDashboard/DashboardPaymentMethod"));
const role = localStorage.getItem(STORAGE_KEYS.ROLE);
const notCenterRole = role === USER_ROLES.PARENT ? USER_ROLES.PARENT : role === USER_ROLES.TEACHER ? USER_ROLES.TEACHER : undefined
const SharedModuleRoutes: RouteObject[] = [
    {
        path: "/",
        element: <ProtectedRoute role={USER_ROLES.CENTER} />,
        children: [
            {
                path: sharedRoutes.center_profile,
                element: (
                    <Suspense fallback={<LoaderSpinner />}>
                        <CenterProfile />
                    </Suspense>
                ),
            },
        ]
    },
    {
        path: "/",
        element: <ProtectedRoute role={notCenterRole} />,
        children: [
            {
                path: sharedRoutes.parent_profile,
                element: (
                    <Suspense fallback={<LoaderSpinner />}>
                        <ParentProfileHistory />
                    </Suspense>
                ),
            },]
    },
    {
        path: "/",
        element: <ProtectedRoute />,
        children: [
            {
                path: sharedRoutes.dashboard_payment_method,
                element: (
                    <Suspense fallback={<LoaderSpinner />}>
                        <DashboardPaymentMethod />
                    </Suspense>
                ),
            },
        ]
    },
    { path: "*", element: <Navigate to={ccfRoutes.home} /> },

]

export default SharedModuleRoutes;
