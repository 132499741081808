import { useEffect } from "react";
import HeroBanner from "../../../medEvaluation_module/components/medEvaluationBanner/HeroBanner";
import MainWrapper from "../../components/layout/MainWrapper";

const AboutUs = () => {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);
  return (
    <>
      <MainWrapper>
        <HeroBanner
          title="About Us"
          paragraphText={
            "Solving the Crisis: Our Approach to Improving Child Care Operations."
          }
          bgClass="https://a360csastorage.blob.core.windows.net/childcare/335ca809-da2d-4f9c-aa70-45da26dba406-fileName-centerBanner.png"
        />
        <div className="">
          <div className="flex lg:flex-row flex-col items-center lg:justify-between gap-4 md:gap-8 lg:gap-16 my-4 sm:my-6 md:my-10 lg:my-16 container">
            <div className="lg:basis-1/2 w-full">
              <img
                className="w-full"
                src="https://a360csastorage.blob.core.windows.net/childcare/daa0fefb-d062-4420-a0ce-636a117603d2-fileName-vision-statement-img.png"
                alt="vision"
              />
            </div>
            <div className="lg:basis-1/2 w-full">
              <h2 className="text-2xl md:text-xll text-secondary font-semibold mb-6">
                Vision Statement
              </h2>
              <p className="text-lg text-secondaryVariant">
                To design innovations that increase operational efficiency for
                child care centers, decrease the cost for parents, and improve
                the onboarding experience for teachers.
              </p>
            </div>
          </div>
          <div className="flex lg:flex-row flex-col items-center lg:justify-between gap-4 md:gap-8 lg:gap-16 mb-4 sm:mb-6 md:mb-10 lg:mb-16 container">
            <div className="lg:basis-1/2 w-full">
              <h2 className="text-2xl md:text-xll text-secondary font-semibold mb-6">
                Mission Statement
              </h2>
              <p className="text-lg text-secondaryVariant">
                We will accomplish our vision by bringing together centers,
                teachers and parents and by providing them with innovative tools
                and services that increase the operational efficiency of child
                care centers.
              </p>
            </div>
            <div className="lg:basis-1/2 w-full">
              <img
                className="w-full"
                src="https://a360csastorage.blob.core.windows.net/childcare/2c92b4e0-b04e-4752-9417-43007be0aa11-fileName-mission-statement-img.png"
                alt="mission"
              />
            </div>
          </div>
          <div className="container">
            <h2 className="text-2xl md:text-xll text-secondary font-semibold mb-8">
              How are we moving Child Care{" "}
              <span className="forwardText"> Forward!</span>{" "}
            </h2>
            <div className="flex lg:flex-row flex-col gap-4 md:gap-8 lg:gap-12 mb-4 md:mb-8 xl:mb-16">
              <div className="lg:basis-1/2 w-full border border-secondaryVariant2 rounded-xl p-6">
                <h2 className="text-xll font-semibold text-secondary mb-6">
                  Existing Inefficiencies in Child Care
                </h2>
                <p className="text-lg text-secondaryVariant">
                  Child Care suffers from many inefficiencies – some that are
                  easy to eliminate and some that are so entrenched that it may
                  take a decade or so to eliminate. An example of the latter is
                  staff turnover. Even before the pandemic, that is before March
                  2020, there was high turnover in the industry – not a
                  surprising fact. Taking care of children all day long is not
                  easy, and teachers can burn out. Additionally, there is no
                  staffing support from the local government – like there is for
                  the public school system (such as a substitute pool to draw
                  from when staffing shortages occur). Another example is lack
                  of innovative software that would help operate child care
                  centers efficiently.
                </p>
              </div>
              <div className="lg:basis-1/2 w-full border border-secondaryVariant2 rounded-xl p-6">
                <h2 className="text-xll font-semibold text-secondary mb-6">
                  Challenges Exacerbated by the Pandemic
                </h2>
                <p className="text-lg text-secondaryVariant">
                  There were, of course, many other problems before March 2020.
                  Add to these the COVID pandemic, the great resignation, and
                  other economic strains since the pandemic, and there are
                  certainly more hardships to face. Many Child Care centers have
                  shut down, the cost of Child Care has increased, and staffing
                  shortages have increased exponentially. The issues are myriad,
                  some easy to solve and some not.
                </p>
              </div>
            </div>
          </div>
          <div className="mb-4 sm:mb-6 md:mb-10 lg:mb-16">
            <div className="container">
              <div className="flex lg:flex-row flex-col gap-4 md:gap-8 lg:gap-16 items-center h-full">
                <div className="lg:w-1/2 h-full w-full">
                  <img
                    className="w-full h-full"
                    src="https://a360csastorage.blob.core.windows.net/childcare/e2f17a94-4c2b-4b73-83af-cdb8f65d5c14-fileName-towords-solution-image.png"
                    alt="towards Solution"
                  />
                </div>
                <div className="lg:w-1/2 h-full w-full">
                  <h2 className="text-xll text-secondary font-semibold mb-8">
                    Steps Towards Solutions
                  </h2>
                  <p className="text-lg text-secondaryVariant">
                    Our goal is to start with problems that have easy solutions,
                    such as making the paperwork to get hired easier and faster
                    for teachers. An example of that is providing Medical
                    Evaluations Online – thus eliminating weeks and sometimes
                    months of waiting for doctors. Meanwhile, we are also
                    working on some of the harder problems, such as convincing
                    the local government to provide Child Care the same support
                    they provide public school system teachers – e.g. a back-up
                    substitute system. Another example is a complete redesign of
                    child care center operating software which will provide
                    actual, actionable data. We are working on many other
                    innovative solutions and will be rolling those out over the
                    next few years.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </MainWrapper>
    </>
  );
};

export default AboutUs;
