import React from "react";
import DashboardWrapper from "../../../shared/components/layout/DashboardWrapper";
import PaymentMethod from "../../../shared/components/paymentMethod/PaymentMethod";

const DashboardPaymentMethod = () => {
  return (
    <>
      <DashboardWrapper>
        <div className="px-6 py-5 overflow-y-scroll h-[calc(100vh-90px)] custom-scrollbar">
          <h2 className="text-2xl text-secondary font-semibold mb-8">
            Confirm Your Payment{" "}
          </h2>
          <PaymentMethod />
        </div>
      </DashboardWrapper>
    </>
  );
};

export default DashboardPaymentMethod;
