export const initContactUsForm = {
    fullName: "",
    email: "",
    phoneNumber: "",
    // state: "",
    message: ""
}

export const initContactUsFormErrors = {
    fullNameError: "",
    emailError: "",
    phoneNumberError: "",
    // stateError: "",
    // messageError: ""
}