import { ApiErrorResponse, create } from "apisauce";
import {
  apiBaseUrlAuth,
  apiBaseUrlCCF,
  apiBaseUrlMedEvals,
  apiBaseUrlPayment,
} from "../constants/environment.constants";
import { STORAGE_KEYS } from "../enums/sharedEnums";
import { refreshToken, signOut } from "../services/auth.service";
import spinnerSvc from "./spinner-service";

let isRefreshing = false;
let waitingQueue: any[] = [];

if (!apiBaseUrlAuth) {
  throw new Error("No Api apiBaseUrl");
}

export const api = create({
  baseURL: apiBaseUrlAuth,
  headers: { Accept: "application/json" },
});

const processQueue = (error: ApiErrorResponse<any> | null, token = null) => {
  waitingQueue.forEach((prom) => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });

  isRefreshing = false;
  waitingQueue = [];
};

api.axiosInstance.interceptors.request.use(
  async (config: any) => {
    const accessToken = localStorage.getItem(STORAGE_KEYS?.ACC_TOKEN);
    if (accessToken !== "") {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }
    spinnerSvc.start();
    return config;
  },

  (err) => console.error(err)
);

api.axiosInstance.interceptors.response.use(
  (response: any) => {
    spinnerSvc.stop();
    return response;
  },

  async (err) => {
    spinnerSvc.stop();
    const originalRequest = err.config;

    if (err.response?.status === 401 && !originalRequest._retry) {
      if (isRefreshing) {
        return new Promise(function (resolve, reject) {
          waitingQueue.push({ resolve, reject });
        })
          .then((token) => {
            originalRequest.headers["Authorization"] = "Bearer " + token;
            return api.axiosInstance(originalRequest);
          })
          .catch((err) => {
            return Promise.reject(err);
          });
      }
      originalRequest._retry = true;
      isRefreshing = true;

      const accessToken = await refreshToken();
      if (accessToken) {
        api.axiosInstance.defaults.headers.common["Authorization"] =
          "Bearer " + accessToken;
        processQueue(null, accessToken);
        return api.axiosInstance(originalRequest);
      } else {
        spinnerSvc.stop();
        signOut();
        // store.dispatch(removeUser());
        return;
      }
    }
    console.error(err.response.data);

    switch (err.response?.status) {
      case 400:
        console.error(err);

        spinnerSvc.stop();

        return err;

      case 401:
        console.error(err);
        signOut();
        isRefreshing = false;
        spinnerSvc.stop();

        return err;

      case 404:
        console.error(err);

        spinnerSvc.stop();

        return err;

      case 500:
        console.error(err);

        spinnerSvc.stop();

        return err;

      default:
        console.error(err);

        spinnerSvc.stop();

        return err;
    }
  }
);

export const paymentApi = create({
  baseURL: apiBaseUrlPayment,
  headers: {
    Accept: "application/json",
  },
});

paymentApi.axiosInstance.interceptors.request.use(
  async (config: any) => {
    const accessToken = localStorage.getItem(STORAGE_KEYS?.ACC_TOKEN);
    if (accessToken !== "") {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }
    spinnerSvc.start();
    return config;
  },

  (err) => console.error(err)
);

paymentApi.axiosInstance.interceptors.response.use(
  (response: any) => {
    spinnerSvc.stop();
    return response;
  },

  async (err) => {
    spinnerSvc.stop();
    const originalRequest = err.config;

    if (err.response?.status === 401 && !originalRequest._retry) {
      if (isRefreshing) {
        return new Promise(function (resolve, reject) {
          waitingQueue.push({ resolve, reject });
        })
          .then((token) => {
            originalRequest.headers["Authorization"] = "Bearer " + token;
            return api.axiosInstance(originalRequest);
          })
          .catch((err) => {
            return Promise.reject(err);
          });
      }
      originalRequest._retry = true;
      isRefreshing = true;

      const accessToken = await refreshToken();
      if (accessToken) {
        api.axiosInstance.defaults.headers.common["Authorization"] =
          "Bearer " + accessToken;
        processQueue(null, accessToken);
        return api.axiosInstance(originalRequest);
      } else {
        spinnerSvc.stop();
        signOut();
        // store.dispatch(removeUser());
        return;
      }
    }
    console.error(err.response.data);

    switch (err.response?.status) {
      case 400:
        console.error(err);

        spinnerSvc.stop();

        return err;

      case 401:
        console.error(err);
        signOut();
        isRefreshing = false;
        spinnerSvc.stop();

        return err;

      case 404:
        console.error(err);

        spinnerSvc.stop();

        return err;

      case 500:
        console.error(err);

        spinnerSvc.stop();

        return err;

      default:
        console.error(err);

        spinnerSvc.stop();

        return err;
    }
  }
);

const medEvalApiPath = "/medical-evaluations";
// apiBaseUrlMedEvals
export const medEvalApi = create({
  baseURL: `${apiBaseUrlMedEvals}${medEvalApiPath}`,
  headers: {
    Accept: "application/json",
  },
});

medEvalApi.axiosInstance.interceptors.request.use(
  async (config: any) => {
    const accessToken = localStorage.getItem(STORAGE_KEYS?.ACC_TOKEN);
    if (accessToken !== "") {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }
    spinnerSvc.start();
    return config;
  },

  (err) => console.error(err)
);

medEvalApi.axiosInstance.interceptors.response.use(
  (response: any) => {
    spinnerSvc.stop();
    return response;
  },

  async (err) => {
    spinnerSvc.stop();
    const originalRequest = err.config;

    if (err.response?.status === 401 && !originalRequest._retry) {
      if (isRefreshing) {
        return new Promise(function (resolve, reject) {
          waitingQueue.push({ resolve, reject });
        })
          .then((token) => {
            originalRequest.headers["Authorization"] = "Bearer " + token;
            return api.axiosInstance(originalRequest);
          })
          .catch((err) => {
            return Promise.reject(err);
          });
      }
      originalRequest._retry = true;
      isRefreshing = true;

      const accessToken = await refreshToken();
      if (accessToken) {
        api.axiosInstance.defaults.headers.common["Authorization"] =
          "Bearer " + accessToken;
        processQueue(null, accessToken);
        return api.axiosInstance(originalRequest);
      } else {
        spinnerSvc.stop();
        signOut();
        // store.dispatch(removeUser());
        return;
      }
    }
    console.error(err.response.data);

    switch (err.response?.status) {
      case 400:
        console.error(err);

        spinnerSvc.stop();

        return err;

      case 401:
        console.error(err);
        signOut();
        isRefreshing = false;
        spinnerSvc.stop();

        return err;

      case 404:
        console.error(err);

        spinnerSvc.stop();

        return err;

      case 500:
        console.error(err);

        spinnerSvc.stop();

        return err;

      default:
        console.error(err);

        spinnerSvc.stop();

        return err;
    }
  }
);

export const ccfApi = create({
  baseURL: `${apiBaseUrlCCF}`,
  headers: {
    Accept: "application/json",
  },
});

ccfApi.axiosInstance.interceptors.request.use(
  async (config: any) => {
    const accessToken = localStorage.getItem(STORAGE_KEYS?.ACC_TOKEN);
    if (accessToken !== "") {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }
    spinnerSvc.start();
    return config;
  },

  (err) => console.error(err)
);

ccfApi.axiosInstance.interceptors.response.use(
  (response: any) => {
    spinnerSvc.stop();
    return response;
  },

  async (err) => {
    spinnerSvc.stop();
    const originalRequest = err.config;

    if (err.response?.status === 401 && !originalRequest._retry) {
      if (isRefreshing) {
        return new Promise(function (resolve, reject) {
          waitingQueue.push({ resolve, reject });
        })
          .then((token) => {
            originalRequest.headers["Authorization"] = "Bearer " + token;
            return api.axiosInstance(originalRequest);
          })
          .catch((err) => {
            return Promise.reject(err);
          });
      }
      originalRequest._retry = true;
      isRefreshing = true;

      const accessToken = await refreshToken();
      if (accessToken) {
        api.axiosInstance.defaults.headers.common["Authorization"] =
          "Bearer " + accessToken;
        processQueue(null, accessToken);
        return api.axiosInstance(originalRequest);
      } else {
        spinnerSvc.stop();
        signOut();
        // store.dispatch(removeUser());
        return;
      }
    }
    console.error(err.response.data);

    switch (err.response?.status) {
      case 400:
        console.error(err);

        spinnerSvc.stop();

        return err;

      case 401:
        console.error(err);
        signOut();
        isRefreshing = false;
        spinnerSvc.stop();

        return err;

      case 404:
        console.error(err);

        spinnerSvc.stop();

        return err;

      case 500:
        console.error(err);

        spinnerSvc.stop();

        return err;

      default:
        console.error(err);

        spinnerSvc.stop();

        return err;
    }
  }
);
