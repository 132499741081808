import { FaLinkedinIn } from "react-icons/fa";
import { GrPhone } from "react-icons/gr";
import { HiOutlineLocationMarker, HiOutlineMail } from "react-icons/hi";
import { IoLogoInstagram } from "react-icons/io";
import { FaXTwitter } from "react-icons/fa6";
import { TiSocialFacebook } from "react-icons/ti";
import { Link, useNavigate } from "react-router-dom";
import { sharedRoutes } from "../../routes/routes";
import { adventureRoutes } from "../../../adventures_module/routes/routes";
import { profferRoutes } from "../../../proffer_module/routes/routes";
import { medEvalRoutes } from "../../../medEvaluation_module/routes/routes";
import { ccfRoutes } from "../../../ccfWebsite_module/routes/routes";
import { TooltipComponent } from "../tooltip/Tooltip";
import { useState } from "react";
const Footer = () => {
  const navigate = useNavigate();
  const handleNavToInBuild = (title: string) => {
    navigate(sharedRoutes.coming_soon, { state: { title } });
  };

  const handleEmailClick = () => {
    const mailtoLink = `mailto:frontdesk@childcareforward.com`;
    window.location.href = mailtoLink;
  };
  const handleLocationClick = () => {
    const address = "8064 New Cut Road, Odenton, MD 21144";
    const encodedAddress = encodeURIComponent(address);
    const locationLink = `https://www.google.com/maps/search/?api=1&query=${encodedAddress}`;
    window.open(locationLink, "_blank");
  };

  const handlePhoneClick = () => {
    const phoneLink = `tel:0016676779200`;
    window.location.href = phoneLink;
  };

  const tooltipText: { [key: string]: string } = {
    "0": "Facebook",
    "1": "Twitter",
    "2": "Instagram",
    "3": "Linkedin",
  };

  const [id, setId] = useState("");
  return (
    <>
      <footer className="bg-secondary -z-10 overflow-hidden">
        <div className="container">
          <div className="grid lg:grid-cols-12 gap-3 lg-gap-3 pt-6 md:pt-9 pb-3">
            <div className="col-span-12 md:col-span-6 xl:col-span-4 lg:col-span-4">
              <img
                className="mb-4"
                src="https://a360csastorage.blob.core.windows.net/childcare/web-logo-footer.png"
                alt="web logo"
              />
              <p className="text-base text-secondaryVariant2 mb-5 md:mb-8 xl:mb-8 max-w-[400px]">
                Transforming childcare operations through innovative services
                and technology​.
              </p>
              <div className="flex items-center gap-5">
                <Link
                  data-tooltip-id="0"
                  onMouseOver={() => setId("0")}
                  onMouseLeave={() => setId("")}
                  to="https://www.facebook.com/profile.php?id=61553050964642"
                  target="_blank"
                  className="rounded-full  hover:scale-125 transform transition-transform duration-200"
                >
                  <TiSocialFacebook className="w-6 h-6 text-primary" />
                </Link>
                <Link
                  data-tooltip-id="2"
                  onMouseOver={() => setId("2")}
                  onMouseLeave={() => setId("")}
                  to="#"
                  className="rounded-full   hover:scale-125 transform transition-transform duration-200"
                >
                  <IoLogoInstagram className="w-6 h-6 text-primary" />
                </Link>

                <Link
                  data-tooltip-id="3"
                  onMouseOver={() => setId("3")}
                  onMouseLeave={() => setId("")}
                  to="#"
                  className="rounded-full  hover:scale-125 transform transition-transform duration-200"
                >
                  <FaLinkedinIn className="w-5 h-5 text-primary" />
                </Link>
                <Link
                  data-tooltip-id="1"
                  onMouseOver={() => setId("1")}
                  onMouseLeave={() => setId("")}
                  to="#"
                  className="rounded-full   hover:scale-125 transform transition-transform duration-200"
                >
                  <FaXTwitter className="w-5 h-5 text-primary" />
                </Link>
              </div>
            </div>
            <div className="xl:col-span-8 lg:col-span-8 grid grid-cols-3">
              <div className="col-span-12 md:col-span-6 lg:col-span-1">
                <h3 className="text-base font-semibold text-white mb-4">
                  Services
                </h3>
                <div className="flex flex-col items-start gap-2">
                  <Link
                    to={adventureRoutes.home}
                    className="xl:text-base text-sm text-secondaryVariant2 hover:text-primary hover:scale-110 transform transition-transform duration-200"
                  >
                    Adventures
                  </Link>
                  <Link
                    to={medEvalRoutes.eval_center_home}
                    className="xl:text-base text-sm text-secondaryVariant2 hover:text-primary hover:scale-110 transform transition-transform duration-200"
                  >
                    Medical Evaluation
                  </Link>
                  {/* <button
                    onClick={() => {
                      handleNavToInBuild("Foray");
                    }}
                    className="xl:text-base text-sm text-secondaryVariant2 hover:text-primary hover:scale-110 transform transition-transform duration-200"
                  >
                    Foray
                  </button>
                  <button
                    onClick={() => {
                      handleNavToInBuild("LMS");
                    }}
                    className="xl:text-base text-sm text-secondaryVariant2 hover:text-primary hover:scale-110 transform transition-transform duration-200"
                  >
                    LMS
                  </button>
                  <button
                    onClick={() => {
                      handleNavToInBuild("JCC Operations");
                    }}
                    className="xl:text-base text-sm text-secondaryVariant2 hover:text-primary hover:scale-110 transform transition-transform duration-200"
                  >
                    JCC Operations
                  </button> */}
                </div>
              </div>
              <div className="col-span-12 sm:col-span-6 lg:col-span-1">
                <h3 className="text-base font-semibold text-white mb-4">
                  Quick link
                </h3>
                <div className="flex items-start flex-col gap-2">
                  <Link
                    to={ccfRoutes.home}
                    className="xl:text-base text-sm text-secondaryVariant2 hover:text-primary hover:scale-110 transform transition-transform duration-200"
                  >
                    Home
                  </Link>
                  <Link
                    to={sharedRoutes.about_us}
                    className="xl:text-base text-sm text-secondaryVariant2 hover:text-primary hover:scale-110 transform transition-transform duration-200"
                  >
                    About Us
                  </Link>
                  {/* <button
                    onClick={() => {
                      handleNavToInBuild("Consultation");
                    }}
                    className="xl:text-base text-sm text-secondaryVariant2 hover:text-primary hover:scale-110 transform transition-transform duration-200"
                  >
                    Consultation
                  </button>
                  <Link
                    to={adventureRoutes.home}
                    className="xl:text-base text-sm text-secondaryVariant2 hover:text-primary hover:scale-110 transform transition-transform duration-200"
                  >
                    Adventures
                  </Link> */}
                  <Link
                    to={sharedRoutes.blogs_screen}
                    className="xl:text-base text-sm text-secondaryVariant2 hover:text-primary hover:scale-110 transform transition-transform duration-200"
                  >
                    Blog
                  </Link>
                  <Link
                    to={sharedRoutes.contact_us}
                    className="xl:text-base text-sm text-secondaryVariant2 hover:text-primary hover:scale-110 transform transition-transform duration-200"
                  >
                    Contact us{" "}
                  </Link>
                </div>
              </div>
              <div className="col-span-12 md:col-span-6 lg:col-span-1 xl:col-span-1">
                <h3 className="text-base font-semibold text-white mb-4">
                  Contact Us
                </h3>
                <Link
                  to="#"
                  onClick={handleEmailClick}
                  className="xl:text-base text-sm text-secondaryVariant2 mb-5 flex items-start xl:gap-3 gap-1 text-start hover:text-primary hover:scale-110 transform transition-transform duration-200"
                >
                  <HiOutlineMail className="xl:w-6 xl:h-6 xl: w-4 h-4 xl:flex-[0_0_24px] flex-[0_0_16px] text-primary" />
                  <p className="flex-1 break-words whitespace-pre-wrap underline">
                    frontdesk@childcareforward.com
                  </p>
                </Link>
                <button
                  onClick={handlePhoneClick}
                  className="xl:text-base text-sm text-secondaryVariant2 mb-5 flex items-start xl:gap-3 gap-1 text-start hover:text-primary hover:scale-110 transform transition-transform duration-200"
                >
                  <GrPhone className="xl:w-6 xl:h-6 xl: w-4 h-4 xl:flex-[0_0_24px] flex-[0_0_16px] text-primary" />
                  667-677-9200
                </button>
              </div>
            </div>
          </div>
        </div>

        <hr className="text-secondaryVariant2/50" />
        <div className="container">
          <div className="flex flex-col items-center justify-center lg:flex-row text-center gap-2 py-6">
            <div className="flex flex-col items-center gap-2 md:flex-row">
              <Link
                to="#"
                className="xl:text-base text-sm text-secondaryVariant2 md:border-r pe-2 border-secondaryVariant2 cursor-text"
              >
                Copyright © 2024 Child Care{" "}
                <i className="text-primary"> Forward! </i>
              </Link>
              <Link
                to="#"
                className="xl:text-base text-sm text-secondaryVariant2 lg:border-r pe-2 border-secondaryVariant2 cursor-text"
              >
                All Rights Reserved
              </Link>
            </div>
            <div className="flex flex-col items-center gap-2 md:flex-row">
              <Link
                to={sharedRoutes.terms_and_conditions}
                className="xl:text-base text-sm text-statusColor underline md:border-r pe-2 hover:text-primary border-secondaryVariant2"
              >
                Terms and Conditions
              </Link>
              <Link
                to={sharedRoutes.privacy_policy}
                className="xl:text-base text-sm text-statusColor underline pe-2 hover:text-primary"
              >
                Privacy Policy
              </Link>
            </div>
          </div>
        </div>
        <TooltipComponent id={id} content={tooltipText[id]} />
      </footer>
    </>
  );
};

export default Footer;
