import React, { useEffect } from "react";
import MainWrapper from "../../components/layout/MainWrapper";
import { Link } from "react-router-dom";

const TermsConditions = () => {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);
  return (
    <>
      <MainWrapper>
        <div className="bg-secondaryNeutral py-12">
          <div className="container">
            <h2 className="text-4xl text-secondary font-semibold mb-4">
              Terms of use{" "}
            </h2>
            <span className="text-lg text-secondaryVariant font-medium">
              Effective Date: November 1, 2023
            </span>
          </div>
        </div>
        <div className="container pt-6 md:pt-[54px]">
          <div className="mb-4 md:mb-6">
            <h2 className="text-2xl text-secondary font-semibold">
              1. Acceptance of Terms {" "}
            </h2>
            <hr className="my-6" />
            <p className="text-base text-secondaryVariant font-normal">
              By accessing and using Child Care Forward! (the "Site"), you agree
              to comply with these Terms of Use. If you do not agree, please do
              not use our Site. 
            </p>
          </div>
          <div className="mb-4 md:mb-6">
            <h2 className="text-2xl text-secondary font-semibold">
              2. Use of the Site {" "}
            </h2>
            <hr className="my-6" />
            <p className="text-base text-secondaryVariant font-normal">
              You may use the Site only for lawful purposes and in accordance
              with these Terms. You agree not to use the Site: {" "}
              <ul className="list-disc list-inside ml-4 mt-6">
                <li>
                  In any way that violates any applicable federal, state, local,
                  or international law. 
                </li>
                <li>
                  To transmit, or procure the sending of, any advertising or
                  promotional material. 
                </li>
              </ul>
            </p>
          </div>
          <div className="mb-4 md:mb-6">
            <h2 className="text-2xl text-secondary font-semibold">
              3. User Accounts {" "}
            </h2>
            <hr className="my-6" />
            <p className="text-base text-secondaryVariant font-normal">
              To access certain features of the Site, you may be required to
              create an account. You are responsible for maintaining the
              confidentiality of your account information and for all activities
              that occur under your account. {" "}
            </p>
          </div>
          <div className="mb-4 md:mb-6">
            <h2 className="text-2xl text-secondary font-semibold">
              4. Content {" "}
            </h2>
            <hr className="my-6" />
            <p className="text-base text-secondaryVariant font-normal">
              You are responsible for any content you submit or upload to the
              Site. You grant us a non-exclusive, worldwide, royalty-free
              license to use, reproduce, and display such content. 
            </p>
          </div>
          <div className="mb-4 md:mb-6">
            <h2 className="text-2xl text-secondary font-semibold">
              5. Intellectual Property {" "}
            </h2>
            <hr className="my-6" />
            <p className="text-base text-secondaryVariant font-normal">
              The Site and its original content, features, and functionality are
              owned by Child Care Forward and are protected by copyright,
              trademark, and other intellectual property laws. 
            </p>
          </div>
          <div className="mb-4 md:mb-6">
            <h2 className="text-2xl text-secondary font-semibold">
              6. Limitation of Liability {" "}
            </h2>
            <hr className="my-6" />
            <p className="text-base text-secondaryVariant font-normal">
              In no event shall Child Care Forward, its directors, employees, or
              agents be liable for any indirect, incidental, special,
              consequential, or punitive damages arising out of your access to
              or use of, or inability to access or use, the Site. 
            </p>
          </div>
          <div className="mb-4 md:mb-6">
            <h2 className="text-2xl text-secondary font-semibold">
              7. Changes to Terms {" "}
            </h2>
            <hr className="my-6" />
            <p className="text-base text-secondaryVariant font-normal">
              We reserve the right to modify these Terms of Use at any time. Any
              changes will be effective immediately upon posting on the Site.
              Your continued use of the Site after any changes indicates your
              acceptance of the new Terms. 
            </p>
          </div>
          <div className="mb-4 md:mb-6">
            <h2 className="text-2xl text-secondary font-semibold">
              8. Governing Law {" "}
            </h2>
            <hr className="my-6" />
            <p className="text-base text-secondaryVariant font-normal">
              These Terms shall be governed by and construed in accordance with
              the laws of the State of Maryland, USA.
            </p>
          </div>
          <div className="mb-4 md:mb-16">
            <h2 className="text-2xl text-secondary font-semibold">
              9. Contact Us {" "}
            </h2>
            <hr className="my-6" />
            <p className="text-base text-secondaryVariant font-normal">
              If you have any questions about these Terms of Use, please contact
              us at: 
              <br />
              <Link
                to={`mailto:frontdesk@childcareforward.com`}
                className="font-medium"
              >
                frontdesk@childcareforward.com  
              </Link>
            </p>
          </div>
        </div>
      </MainWrapper>
    </>
  );
};

export default TermsConditions;
