export const profferRoutes = {
  home: "/pr/home",
  center_home: "/pr/center-home",
  parent_home: "/pr/parent-home",
  proffer_list: "/pr/proffer-list",
  center_profile_view: "/pr/center-profile-view",
  dashboard: "/pr/dashboard",
  add_vacancy: "/pr/add-vacancy",
  bidding_request: "/pr/bidding-request",
  vacancies_history: "/pr/vacancies-history",
  view_vacancy: "/pr/view-vacancy",
  notifications: "/pr/notifications",
  parent_dashboard_vacancies: "/pr/parent-dashboard",
  parent_dashboard_history: "/pr/parent-history",
  // center_profile: "/center-profile",
  // parent_profile: "/parent-profile",
};