import { useEffect, useState } from "react";
import { GoStack } from "react-icons/go";
import { useLocation } from "react-router-dom";
import CenterViewBanner from "../../components/centerViewBanner/CenterViewBanner";
import MainWrapper from "../../components/layout/MainWrapper";
import { ERR_MSGS } from "../../constants/errorMsgs";
import { message } from "../../constants/toastMessages";
import { subNewsLetter } from "../../services/auth.service";
import { showErrorToast, showSuccessToast } from "../../utils/toast";
import HeroBanner from "../../../medEvaluation_module/components/medEvaluationBanner/HeroBanner";

const ComingSoon = () => {
  const [email, setEmail] = useState<string>("");
  const title = useLocation().state.title;

  const handleSubToNewsLetter = async () => {
    if (!email) {
      showErrorToast(ERR_MSGS.Empty_Field);
      return;
    }

    try {
      const res = await subNewsLetter(email);
      console.log(res);

      if (res?.ok) {
        showSuccessToast(message.Success_Newsletter_Sub);
        setEmail("");
      } else {
        if (Array.isArray(res?.error.message)) {
          showErrorToast(res?.error.message[0]);
        } else {
          showErrorToast(res?.error.message);
        }
      }
    } catch (error) {
      console.error("Error subscribing to newsletter:", error);
      showErrorToast(ERR_MSGS.Something_Went_Wrong);
    }
  };

  useEffect(() => {
    window.scroll(0, 0);
  }, [title]);
  return (
    <>
      <MainWrapper>
        <HeroBanner
          title="Taking the Child Care Industry "
          highlightedText="Forward!"
          paragraphText={
            "Transforming childcare operations through innovative services and technology​."
          }
          bgClass="https://a360csastorage.blob.core.windows.net/childcare/44a5095a-42ec-484c-9738-fe03fa825968-fileName-consultation-banner.png"
        />{" "}
        <div className="container pt-14 pb-24 flex flex-col justify-center items-center mx-auto ">
          <div className="max-w-[576px] text-center flex flex-col items-center">
            <img
              className="mb-4 md:mb-8 xl:mb-12"
              src="./images/rocket_launch_flatline.svg"
              alt="rocket"
            />
            <h2 className="text-2xl text-secondary font-semibold mb-3">
              We're Building Something Great!
            </h2>
            <p className="text-lg text-secondaryVariant mb-4 md:mb-8 xl:mb-12">
              Our team is working hard behind the scenes to bring <br /> you an
              amazing experience. <br /> <br /> Thank you for your patience
              while we put on the finishing touches.
            </p>
            {/* <p className="text-base text-secondaryVariant font-semibold flex items-center mb-6 gap-1">
              Stay Updated:
              <span className="text-sm font-normal">
                {" "}
                Subscribe for the latest news and updates.
              </span>
            </p>
            <div className="flex items-center gap-2">
              <input
                className="text-sm text-secondaryVariant rounded-md border border-secondaryVariant2 py-3.5 px-2.5"
                type="email"
                placeholder="Enter your email"
                onChange={(e) => setEmail(e?.target?.value)}
              />
              <button
                className="btnPrimary max-w-[163px] flex items-center justify-center gap-1"
                onClick={handleSubToNewsLetter}
              >
                {" "}
                <GoStack className="w-5 h-5" />
                Subscribe
              </button>
            </div> */}
          </div>
        </div>
      </MainWrapper>
    </>
  );
};

export default ComingSoon;
