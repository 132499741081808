import React, { useState } from "react";

interface DropdownOption {
  label: string;
  startIcon: React.ReactNode;
  endIcon: React.ReactNode;
}

interface DropdownProps {
  options: DropdownOption[];
  placeholder: string;
}
const CostumDropdown: React.FC<DropdownProps> = ({ options, placeholder }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState<DropdownOption | null>(
    null
  );

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (option: DropdownOption) => {
    setSelectedOption(option);
    setIsOpen(false);
  };

  return (
    <div className="relative inline-block text-left w-full">
      <div>
        <button
          type="button"
          className="inline-flex justify-between w-full rounded-md border border-secondaryVariant2 shadow-sm px-3 py-3.5 bg-secondaryNeutral text-base text-secondary focus:outline-none underline"
          id="options-menu"
          aria-expanded={isOpen}
          aria-haspopup="true"
          onClick={toggleDropdown}
        >
          <span className="flex items-center">
            {selectedOption ? (
              <>
                <span className="mr-2">{selectedOption.startIcon}</span>
                {selectedOption.label}
                <span className="ml-2">{selectedOption.endIcon}</span>
              </>
            ) : (
              placeholder
            )}
          </span>
          <svg
            className="-mr-1 ml-2 h-5 w-5"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            aria-hidden="true"
          >
            <path
              fillRule="evenodd"
              d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
              clipRule="evenodd"
            />
          </svg>
        </button>
      </div>

      {isOpen && (
        <div
          className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 w-full"
          role="menu"
          aria-orientation="vertical"
          aria-labelledby="options-menu"
        >
          <ul className="py-1" role="none">
            {options.map((option) => (
              <li
                key={option.label}
                className="flex justify-between items-center w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 cursor-pointer underline"
                role="menuitem"
                onClick={() => handleOptionClick(option)}
              >
                <span className="flex items-center">
                  <span className="mr-2 underline">{option.startIcon}</span>
                  {option.label}
                </span>
                <span>{option.endIcon}</span>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default CostumDropdown;
