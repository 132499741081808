export const medEvalRoutes = {
  eval_center_home: "/md/home",
  eval_teacher_home: "/md/evaluation-teacher",
  book_appointment_teacher: "/md/book-appointment-teacher",
  book_appointment_center: "/md/book-appointment-center",
  book_appointment_teacher_dashboard: "/md/dashboard/book-appointment-teacher",
  book_appointment_center_dashboard: "/md/dashboard/book-appointment-center",
  dashboard: "/md/dashboard",
  confirm_booking: "/md/confirm-booking",
  confirm_booking_dashboard:"/md/confirm-booking-dashboard",
  eval_successful: "/md/evaluation-successful",

  // center_dashboard: "/md/center-dashboard",
  // center_history: "/md/center-history",
  // eval_dashboard: "/md/dashboard",
};