import { toast } from "react-toastify";

export const showSuccessToast = (message: string, options = {}) => {
  toast.success(message, options);
};

export const showErrorToast = (message: string, options = {}) => {
  toast.error(message, options);
};

export const showInfoToast = (message: string, options = {}) => {
  toast.info(message, options);
};

export const showWarningToast = (message: string, options = {}) => {
  toast.warn(message, options);
};
