import React, { lazy, Suspense } from "react";
import { RouteObject } from "react-router-dom";
import LoaderSpinner from "../shared/components/Loader/Loader";
import { adventureRoutes } from "./routes/routes";

const AdventuresScreen = lazy(() => import("./pages/adventures/AdventuresScreen"));
const AdventureEpisodeScreen = lazy(() => import("./pages/adventuresEpisode/AdventureEpisodeScreen"));

const AdventureModuleRoutes: RouteObject[] = [
  {
    path: adventureRoutes.home,
    element: (
      <Suspense fallback={<LoaderSpinner />}>
        <AdventuresScreen />
      </Suspense>
    )
  },
  {
    path: adventureRoutes.episode,
    element: (
      <Suspense fallback={<LoaderSpinner />}>
        <AdventureEpisodeScreen />
      </Suspense>
    )
  },
];

export default AdventureModuleRoutes;
