import React from "react";
import { GrLocation } from "react-icons/gr";

import { CiCalendar } from "react-icons/ci";

const PriceDetail = () => {
  return (
    <>
      <div className="p-6">
        <div className="flex items-center gap-4 mb-6">
          <span className="text-base text-secondary flex items-center gap-3">
            <img src="./images/globe-05.svg" alt="globe" />
            Childcare Center, Maryland
          </span>
          <span className="text-base text-secondary flex items-center gap-3">
            <GrLocation className="w-6 h-6 text-secondaryVariant2" />
            10054{" "}
          </span>
        </div>
        <div className="">
          <h2 className="text-2xl font-semibold text-secondary mb-6">
            PrOffer on - 03-06 month child{" "}
          </h2>

          <p className="text-base text-secondaryVariant max-w-[667px] mb-4 md:mb-8 xl:mb-12">
            Donec eget dignissim id sit egestas in consequat volutpat elementum
            donec dolor sit amet consDonec eget dignissim id sit egestas.
          </p>
          <strong className="text-2xl text-primary font-semibold block">
            Price: $250
          </strong>
        </div>
      </div>
    </>
  );
};

export default PriceDetail;
