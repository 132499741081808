import React from "react";

interface ButtonGroupProps {
  options: string[];
  selectedButton: string;
  getIcon: (option: string, isSelected: boolean) => React.ReactNode; // Update the getIcon function type to accept boolean
  handleButtonClick: (role: string) => void;
}

const ButtonGroup: React.FC<ButtonGroupProps> = ({
  options,
  selectedButton,
  getIcon,
  handleButtonClick,
}) => {
  return (
    <div className="w-full border-b border-secondaryVariant2">
      {options.map((option) => {
        const isSelected = selectedButton === option; // Determine if the button is selected
        return (
          <button
            key={option}
            onClick={() => handleButtonClick(option)}
            className={`groupBTn text-base md:text-lg inline-flex items-center group gap-3 mb-[-1px] font-medium text-left py-1.5 md:py-3 px-[22px] relative min-w-[143px] ${
              isSelected
                ? "text-[#DF7A5E] border-b-[2px] border-[#DF7A5E]" 
                : "text-secondaryVariant hover:text-primary"
            }`}
          >
            <i>{getIcon(option, isSelected)}</i> 
            {option.charAt(0).toUpperCase() + option.slice(1)}
          </button>
        );
      })}
    </div>
  );
};

export default ButtonGroup;
