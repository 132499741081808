import React, { useEffect, useState } from "react";
import Topbar from "../Header/dashboard-header/Topbar";
import Sidebar from "../Header/dashboard-header/Sidebar";

const DashboardWrapper = ({ children }: { children: React.ReactNode }) => {
  
  const [isAbsolute, setIsAbsolute] = useState(false);
  
  const [isExpanded, setIsExpanded] = useState(true);
  const [width, setWidth] = useState("80px");
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 1200);

  const toggleSidebar = () => {
    if (isSmallScreen) {
      setIsAbsolute(!isExpanded);
      setWidth(isExpanded ? "80px" : "100%");
    } else {
      setIsExpanded(!isExpanded);
      setWidth(isExpanded ? "80px" : "290px");
    }
    setIsExpanded(!isExpanded);
  };
  
  useEffect(() => {
    const handleResize = () => {
      const smallScreen = window.innerWidth <= 1200;
      setIsSmallScreen(smallScreen);

      if (!smallScreen) {
        setIsAbsolute(false);
        setWidth(isExpanded ? "290px" : "80px");
      } else {
        setIsAbsolute(isExpanded);
        setWidth(isExpanded ? "290px" : "80px");
      }
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [isExpanded]);

  return (
    <>
      <div className=" bg-natural min-h-screen">
        <Sidebar isAbsolute={isAbsolute} toggleSidebar={toggleSidebar} width={width} isExpanded={isExpanded} />
        <div className={` transition-all ease-in-out duration-300 ${isExpanded ?'pl-[290px]' : 'pl-[80px]'} min-h-screen`}>
          <Topbar />
          {children}
        </div>
      </div>
    </>
  );
};

export default DashboardWrapper;
