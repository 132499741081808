import React, { useEffect, useState } from "react";
import MainWrapper from "../../components/layout/MainWrapper";
import CenterViewBanner from "../../components/centerViewBanner/CenterViewBanner";
import Trending from "../../components/trending/Trending";
import BlogCards from "../../components/blogCards/BlogCards";
import { IBlog } from "../../../medEvaluation_module/types/blog.interface";
import HeroBanner from "../../../medEvaluation_module/components/medEvaluationBanner/HeroBanner";

const BlogsScreen = () => {
  const [trendingBlog, setTrendingBlog] = useState<IBlog>();
  useEffect(() => {
    window.scroll(0, 0);
  }, []);
  return (
    <>
      <MainWrapper>
        <div className="mb-4 md:mb-10 xl:mb-16">
          <HeroBanner
            title="Taking the Child Care
Industry "
            highlightedText="Forward!"
            paragraphText={
              "Transforming childcare operations through innovative services and technology​."
            }
            bgClass="https://a360csastorage.blob.core.windows.net/childcare/a0317cc0-5be3-48ad-8d73-599ac16fc1e5-fileName-blogs-banner.png"
          />{" "}
        </div>
        <div className="container">
          <div className="mb-4 sm:mb-8 md:mb-12 xl:mb-16">
            <Trending trendingBlog={trendingBlog as IBlog} />
          </div>
        </div>
        <div>
          <BlogCards setTrendingBlog={setTrendingBlog} />
        </div>
      </MainWrapper>
    </>
  );
};

export default BlogsScreen;
