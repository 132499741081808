import { lazy, Suspense } from "react";
import { RouteObject } from "react-router-dom";
import LoaderSpinner from "../shared/components/Loader/Loader";
import { medEvalRoutes } from "./routes/routes";
import ProtectedRoute from "../shared/components/RouteProtection";
const MedEvaluationTeacher = lazy(() => import("./pages/medEvaluationTeacher/MedEvaluationTeacher"));
const MedEvaluationDate = lazy(() => import("./pages/medEvaluationDate/MedEvaluationDate"));
const MedEvlConfirmBooking = lazy(() => import("./pages/medEvlConfirmBooking/MedEvlConfirmBooking"));
const MedEvalConfirmBookingDashboard = lazy(() => import("./pages/medEvlConfirmBooking/MedEvalConfirmBookingDashboard"));
const BookAppointmentParentDashboard = lazy(() => import("./pages/medEvaluationDashboard/BookAppointment"));
const BookAppointmentCenterDashboard = lazy(() => import("./pages/medEvaluationDashboard/BookAppointmentDashboard"));
const MedEvaluationSuccessful = lazy(() => import("./pages/medEvaluationSuccessful/MedEvaluationSuccessful"));
const MedEvaluationCenter = lazy(() => import("./pages/medEvaluationCenter/MedEvaluationCenter"));
const MedEvaluationDashboard = lazy(() => import("./pages/medEvaluationDashboard/MedEvaluationDashboard"));
const CenterBooking = lazy(() => import("./pages/medEvaluationCenterBooking/CenterBooking"));

const MedEvalModuleRoutes: RouteObject[] = [
  {
    path: medEvalRoutes.eval_center_home,
    element: (
      <Suspense fallback={<LoaderSpinner />}>
        <MedEvaluationCenter />
      </Suspense>
    )
  },
  // {
  //   path: medEvalRoutes.eval_teacher_home,
  //   element: (
  //     <Suspense fallback={<LoaderSpinner />}>
  //       <MedEvaluationTeacher />
  //     </Suspense>
  //   )
  // },
  {
    path: "/",
    element: <ProtectedRoute />,
    children: [
      {
        path: medEvalRoutes.book_appointment_teacher,
        element: (
          <Suspense fallback={<LoaderSpinner />}>
            <MedEvaluationDate />
          </Suspense>
        )
      },
      {
        path: medEvalRoutes.book_appointment_center,
        element: (
          <Suspense fallback={<LoaderSpinner />}>
            <CenterBooking />
          </Suspense>
        )
      },
      {
        path: medEvalRoutes.book_appointment_teacher_dashboard,
        element: (
          <Suspense fallback={<LoaderSpinner />}>
            <BookAppointmentParentDashboard />
          </Suspense>
        )
      },
      {
        path: medEvalRoutes.book_appointment_center_dashboard,
        element: (
          <Suspense fallback={<LoaderSpinner />}>
            <BookAppointmentCenterDashboard />
          </Suspense>
        )
      },
      {
        path: medEvalRoutes.dashboard,
        element: (
          <Suspense fallback={<LoaderSpinner />}>
            <MedEvaluationDashboard />
          </Suspense>
        )
      },
      {
        path: medEvalRoutes.eval_successful,
        element: (
          <Suspense fallback={<LoaderSpinner />}>
            <MedEvaluationSuccessful />
          </Suspense>
        )
      },
      {
        path: medEvalRoutes.confirm_booking,
        element: (
          <Suspense fallback={<LoaderSpinner />}>
            <MedEvlConfirmBooking />
          </Suspense>
        )
      },
      {
        path: medEvalRoutes.confirm_booking_dashboard,
        element: (
          <Suspense fallback={<LoaderSpinner />}>
            <MedEvalConfirmBookingDashboard />
          </Suspense>
        )
      },
    ]
  },

];

export default MedEvalModuleRoutes;
