import { createSlice } from "@reduxjs/toolkit";
import { INotifications } from "../../modules/shared/types/INotifications.interface";
interface Init {
    Notifications: INotifications[] | null;
}
const initialState: Init = {
    Notifications: [{
        _id: "",
        message: "",
        title: "",
        isViewed: false,
        category: "",
        userId: {
            _id: "",
            name: "",
            email: "",
        },
        createdAt: "",
        updatedAt: "",
        __v: 0
    }]
};

export const notificationSlice = createSlice({
    name: "notifications",
    initialState,
    reducers: {
        setNotifications: (state, action) => {
            state.Notifications = action?.payload;
        },

    },
});

export const { setNotifications } = notificationSlice.actions;

export default notificationSlice.reducer;
