import { adventureRoutes } from "../../adventures_module/routes/routes";
import { ccfRoutes } from "../../ccfWebsite_module/routes/routes"
import { medEvalRoutes } from "../../medEvaluation_module/routes/routes";
import { sharedRoutes } from "../routes/routes";

export const documentTitle = (pathName: string) => {

    switch (pathName) {
        //home and shared routes
        case ccfRoutes.home:
            document.title = "Home | CCF"
            break;
        case sharedRoutes.about_us:
            document.title = "About us | CCF"
            break;
        case sharedRoutes.contact_us:
            document.title = "Contact us | CCF"
            break;
        case sharedRoutes.blogs_screen:
            document.title = "Blogs | CCF"
            break;
        case sharedRoutes.auth_verify:
            document.title = "Authentication | CCF"
            break;
        case sharedRoutes.privacy_policy:
            document.title = "Privacy Policy | CCF"
            break;
        case sharedRoutes.terms_and_conditions:
            document.title = "Terms and Conditions | CCF"
            break;
        case sharedRoutes.dashboard_payment_method:
            document.title = "Payment Method | CCF"
            break;
        case sharedRoutes.blogs_details:
            document.title = "Blog Detail | CCF"
            break;
        case sharedRoutes.coming_soon:
            document.title = "Coming Soon | CCF"
            break;
        case sharedRoutes.parent_profile || sharedRoutes.center_profile:
            document.title = "Profile | CCF"
            break;
        //adventures    
        case adventureRoutes.home:
            document.title = "Adventures | CCF"
            break;
        case adventureRoutes.episode:
            document.title = "Adventures Episodes | CCF"
            break;
        //med-eval
        case medEvalRoutes.eval_center_home:
            document.title = "Med-Eval | CCF"
            break;
        case medEvalRoutes.book_appointment_teacher:
            document.title = "Teacher - Book Appointment | CCF"
            break;
        case medEvalRoutes.book_appointment_center:
            document.title = "Center - Book Appointment | CCF"
            break;
        case medEvalRoutes.book_appointment_teacher_dashboard:
            document.title = "Teacher Dashboard - Book Appointment | CCF"
            break;
        case medEvalRoutes.book_appointment_center_dashboard:
            document.title = "Center Dashboard - Book Appointment | CCF"
            break;
        case medEvalRoutes.dashboard:
            document.title = "Dashboard Med-Eval | CCF"
            break;
        case medEvalRoutes.confirm_booking:
            document.title = "Confirm Booking | CCF"
            break;
        case medEvalRoutes.confirm_booking_dashboard:
            document.title = "Confirm Booking Dashboard | CCF"
            break;
        case medEvalRoutes.eval_successful:
            document.title = "Successful Booking | CCF"
            break;
        default:
            document.title = "Child Care Forward"
    }
}