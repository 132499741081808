import { createSlice } from "@reduxjs/toolkit";
import { STORAGE_KEYS } from "../../modules/shared/enums/sharedEnums";
import { IUser } from "../../modules/shared/types/IUser.interface";
interface Init {
  User: IUser | null;
}
const initialState: Init = {
  User: {
    _id: "",
    profilePictureUrl: "",
    name: "",
    city: "",
    postalCode: "",
    email: "",
    isSocialLogin: false,
    customerId: "",
    centerId: "",
    contactNumber: "",
    provider: "",
    isDeactivated: false,
    isProfileCompleted: false,
    isVerified: false,
    createdOn: "",
    __v: 0,
    bookingsCustomerId: "",
    prevUser: false,
    iat: 0,
    exp: 0,
  },
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setAuthUser: (state, action) => {
      state.User = action.payload;
    },
    removeUser: (state) => {
      Object.values(STORAGE_KEYS).forEach((key) =>
        localStorage.removeItem(key)
      )
      state.User = null;
    },
    loadUser: (state) => {
      const user = JSON.parse(localStorage.getItem(STORAGE_KEYS?.USER)!);
      if (user) {
        state.User = user;
      }
    },
  },
});

export const { setAuthUser, removeUser, loadUser } = authSlice.actions;

export default authSlice.reducer;
