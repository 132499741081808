const Icons = {
  BuildingIcon: (color = "currentColor", w = 24, h = 24) => (
    <svg
      width={w}
      height={h}
      viewBox="0 0 32 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.25 13.5H4.9C4.05992 13.5 3.63988 13.5 3.31901 13.6635C3.03677 13.8073 2.8073 14.0368 2.66349 14.319C2.5 14.6399 2.5 15.0599 2.5 15.9V28.5M22.75 13.5H27.1C27.9401 13.5 28.3601 13.5 28.681 13.6635C28.9632 13.8073 29.1927 14.0368 29.3365 14.319C29.5 14.6399 29.5 15.0599 29.5 15.9V28.5M22.75 28.5V6.3C22.75 4.61984 22.75 3.77976 22.423 3.13803C22.1354 2.57354 21.6765 2.1146 21.112 1.82698C20.4702 1.5 19.6302 1.5 17.95 1.5H14.05C12.3698 1.5 11.5298 1.5 10.888 1.82698C10.3235 2.1146 9.8646 2.57354 9.57698 3.13803C9.25 3.77976 9.25 4.61984 9.25 6.3V28.5M31 28.5H1M14.5 7.5H17.5M14.5 13.5H17.5M14.5 19.5H17.5"
        stroke={color}
        strokeWidth="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  ),
  User: (color = "currentColor", w = 24, h = 24) => (
    <svg
      width={w}
      height={h}
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M30 31.5C30 29.4067 30 28.36 29.7416 27.5083C29.1599 25.5907 27.6593 24.0901 25.7417 23.5084C24.89 23.25 23.8433 23.25 21.75 23.25H14.25C12.1567 23.25 11.11 23.25 10.2583 23.5084C8.34068 24.0901 6.84006 25.5907 6.25836 27.5083C6 28.36 6 29.4067 6 31.5M24.75 11.25C24.75 14.9779 21.7279 18 18 18C14.2721 18 11.25 14.9779 11.25 11.25C11.25 7.52208 14.2721 4.5 18 4.5C21.7279 4.5 24.75 7.52208 24.75 11.25Z"
        stroke={color}
        strokeWidth="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  ),
  Users: (color = "currentColor", w = 24, h = 24) => (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22 21V19C22 17.1362 20.7252 15.5701 19 15.126M15.5 3.29076C16.9659 3.88415 18 5.32131 18 7C18 8.67869 16.9659 10.1159 15.5 10.7092M17 21C17 19.1362 17 18.2044 16.6955 17.4693C16.2895 16.4892 15.5108 15.7105 14.5307 15.3045C13.7956 15 12.8638 15 11 15H8C6.13623 15 5.20435 15 4.46927 15.3045C3.48915 15.7105 2.71046 16.4892 2.30448 17.4693C2 18.2044 2 19.1362 2 21M13.5 7C13.5 9.20914 11.7091 11 9.5 11C7.29086 11 5.5 9.20914 5.5 7C5.5 4.79086 7.29086 3 9.5 3C11.7091 3 13.5 4.79086 13.5 7Z"
        stroke={color}
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  ),
};

export default Icons;
